import { LegacyRef, useEffect } from 'react';
import cn from 'classnames';

interface GoogleAdProps {
  className: string;
  innerRef?: LegacyRef<HTMLModElement>;
}

export default function GoogleAd({ className, innerRef }: GoogleAdProps) {
  useEffect(() => {
    // @ts-ignore
    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      ref={innerRef}
      className={cn('adsbygoogle', 'testadsense', className)}
      style={{ display: 'block' }}
      data-ad-client="ca-pub-1238322915316316"
      data-ad-slot="3818893417"
      data-ad-format="fluid"
      data-full-width-responsive="true"
    />
  );
}
