import { useState } from 'react';
import { createContainer } from 'containers';
import { ContentIndexQuery, ContentIndexQueryVariables, rubyQueries } from 'queries';
import usePagination from 'hooks/usePagination';
import filterArticles from 'helpers/filterArticles';

export interface ContentContainerProps {
  query: ContentIndexQuery;
  variables: ContentIndexQueryVariables;
}

export const [ContentContainer, useContentContainer] = createContainer(({ query, variables }: ContentContainerProps) => {
  const [articles, setArticles] = useState(filterArticles(query.articles.nodes));

  const { handleLoadMore, hasNextPage } = usePagination({
    onLoadMore: ({ articles: { nodes, pageInfo } }) => {
      setArticles(articles => [...articles, ...filterArticles(nodes)]);
      return pageInfo;
    },
    pageInfo: query.articles.pageInfo,
    ttl: 60 * 5,
    query: rubyQueries.contentIndexQuery,
    variables
  });

  return [
    {
      articles,
      hasNextPage
    },
    {
      handleLoadMore
    }
  ];
});
