export default function Fire() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={28}>
      <path
        d="M17.334 2.8a32.409 32.409 0 0 0-3.012 3.281A42.079 42.079 0 0 0 9 0C3.736 4.986 0 11.484 0 15.4 0 22.359 5.373 28 12 28s12-5.641 12-12.6c0-2.913-2.786-8.922-6.666-12.6Zm-.06 20.063a8.758 8.758 0 0 1-5.12 1.637 8.894 8.894 0 0 1-6.11-2.292 7.994 7.994 0 0 1-1.922-2.756 9.067 9.067 0 0 1-.693-3.552c0-3.25 2.293-5.818 5.587-9.85 1.607 1.892.991 1.082 5.395 6.814L17.732 9c1.3 2.2 1.488 2.493 1.866 3.232a8.8 8.8 0 0 1 .8 5.721 7.882 7.882 0 0 1-3.124 4.91Z"
        fill="#002241"
      />
      <path
        d="M16.291 21.429a7.1 7.1 0 0 1-4.138 1.321 6.659 6.659 0 0 1-7.01-6.85c0-2.111 1.3-3.972 3.9-7.15.371.438 5.294 6.855 5.294 6.855l3.141-3.657c.222.375.424.741.6 1.094a6.61 6.61 0 0 1-1.791 8.39Z"
        fill="#00bbf2"
      />
    </svg>
  );
}
