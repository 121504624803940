import ArticleBlock, { ArticleBlockLayout } from 'components/ArticleBlock';
import ContentBlock from 'components/ContentBlock';
import { useContentContainer } from '..';
import styles from './styles/ContentLargeHero.module.scss';

export default function ContentLargeHero() {
  const [{ articles }] = useContentContainer();

  return (
    <ContentBlock className={styles.Block}>
      <div className={styles.Container}>
        <div className={styles.Main}>
          <ArticleBlock article={articles[0]} layout={ArticleBlockLayout.HERO_LARGE} />
        </div>
        <div className={styles.Secondary}>
          {articles.length > 1 && <ArticleBlock article={articles[1]} layout={ArticleBlockLayout.HERO_SMALL} />}
        </div>
        {articles.slice(2, 6).map(article => (
          <ArticleBlock key={article.id} article={article} layout={ArticleBlockLayout.TEXT_ONLY} />
        ))}
      </div>
    </ContentBlock>
  );
}
