import { onEvent, SPONSOR_CLICK, SPONSOR_VIEW } from './events';

export const SPONSOR_TRACKER = 'adTracker';

export type TrackerType = typeof SPONSOR_TRACKER;

export function gaLocation(url: string) {
  if (window.ga) {
    window.ga('set', 'location', url);
  } else {
    console.log('[GA ANALYTICS]', 'set', 'location', url);
  }
}

export function gaSend<FieldsObject>(tracker: TrackerType | undefined, fieldsObject: FieldsObject) {
  const send = [tracker, 'send'].filter(v => !!v).join('.');

  if (window.ga) {
    window.ga(send, fieldsObject);
  } else {
    console.log('[GA ANALYTICS]', send, fieldsObject);
  }
}

export function gaPageView({ tracker, path }: { tracker?: TrackerType; path?: string } = {}) {
  gaSend(tracker, { hitType: 'pageview', page: path });
}

export function gaEvent({
  tracker,
  event
}: {
  tracker?: TrackerType;
  event: { category: string; action: string; label?: string; value?: string };
}) {
  return new Promise<void>(resolve => {
    gaSend(tracker, {
      hitType: 'event',
      eventCategory: event.category,
      eventAction: event.action,
      eventLabel: event.label,
      eventValue: event.value,
      transport: 'beacon'
    });
    resolve();
  });
}

/**** EVENTS *****/

onEvent(SPONSOR_CLICK, ({ sponsor, size }) => {
  gaEvent({
    tracker: SPONSOR_TRACKER,
    event: {
      category: `Ad-${size}`,
      action: 'click',
      label: sponsor.name
    }
  });
});

onEvent(SPONSOR_VIEW, ({ sponsor, size }) => {
  gaPageView({
    tracker: SPONSOR_TRACKER,
    path: `${window.location.pathname}/${sponsor.slug}/${size}`
  });
});
