import { Fragment } from 'react';
import ArticleBlock, { ArticleBlockLayout } from 'components/ArticleBlock';
import { useContentContainer } from '..';
import styles from './styles/ContentInfiniteFeed.module.scss';
import cn from 'classnames';
import InfiniteScroll from '@/components/InfiniteScroll';
import MinuteMediaAd from '@/components/ads/MinuteMediaAd';

export enum ContentFeedLayout {
  CARDS = 'Cards',
  LIST = 'List'
}

interface ContentInfiniteFeedProps {
  layout: ContentFeedLayout;
  startAt?: number;
}

export default function ContentInfiniteFeed({ layout, startAt = 1 }: ContentInfiniteFeedProps) {
  const [{ articles, hasNextPage }, { handleLoadMore }] = useContentContainer();

  return (
    <InfiniteScroll hasNextPage={hasNextPage} onEndReached={handleLoadMore}>
      <div className={cn(styles.Feed, layout === ContentFeedLayout.CARDS && styles[layout])}>
        {articles.slice(startAt - 1).map((article, i) => (
          <Fragment key={article.id}>
            {i === 8 && (
              <div className={styles.FullWidth}>
                <MinuteMediaAd placement="IN_FEED" />
              </div>
            )}
            {i === 16 && (
              <div className={styles.FullWidth}>
                <MinuteMediaAd placement="IN_FEED_2" />
              </div>
            )}
            <ArticleBlock
              article={article}
              layout={layout === ContentFeedLayout.CARDS ? ArticleBlockLayout.CARD : ArticleBlockLayout.FEED}
              first={i === 0}
            />
          </Fragment>
        ))}
      </div>
    </InfiniteScroll>
  );
}
