import { MouseEvent, useEffect, useState } from 'react';
import { useIpInfo } from 'stores/ipInfoStore';
import cn from 'classnames';
import 'components/styles/Sponsor.scss';
import GoogleAd from './GoogleAd';
import { useInView } from 'react-intersection-observer';
import { GOOGLE_AD_VIEW, SPONSOR_CLICK, SPONSOR_VIEW, triggerEvent } from 'analytics/events';
import StnAd from './StnAd';
import ExcoAd from './ExcoAd';
import { gaEvent, gaPageView, SPONSOR_TRACKER } from 'analytics/ga';
import { useApplicationContainer } from 'containers/ApplicationContainer';

export interface SponsorType {
  name: string;
  slug: string;
  target: string;
}

export const WA_FED: SponsorType = {
  name: 'WaFd Bank',
  slug: 'wafd-bank',
  target: 'https://www.wafdbank.com/personal-banking/free-checking-account'
};

const RANK_ONE: SponsorType = {
  name: 'RankOne',
  slug: 'rank-one',
  target: 'https://rankone.com/scorebook'
};

const RANK_ONE_TWO: SponsorType = {
  name: 'RankOne',
  slug: 'rank-one-two',
  target: 'https://rankone.com/scorebook'
};

const FAN_APP_IOS: SponsorType = {
  name: 'Fan App iOs',
  slug: 'fan-app-ios',
  target: 'https://apps.apple.com/us/app/sblive-sports/id1550337702'
};

const FAN_APP_ANDROID: SponsorType = {
  name: 'Fan App Android',
  slug: 'fan-app-android',
  target: 'https://play.google.com/store/apps/details?id=com.scorebooklive.sblivesports&hl=en_US&gl=US'
};

const WIAA: SponsorType = {
  name: 'WIAA',
  slug: 'wiaa',
  target: 'https://scorebooklive.com/washington/'
};

const GOOGLE_AD: SponsorType = {
  name: 'google',
  slug: 'google',
  target: ''
};

const ACADEMY_SPORTS: SponsorType = {
  name: 'academy',
  slug: 'academy',
  target: 'https://www.academy.com/'
};

const ADOT: SponsorType = {
  name: 'adot',
  slug: 'adot',
  target: 'https://azdot.gov/motor-vehicles/driver-services/arizona-travel-id'
};

const STN_AD: SponsorType = {
  name: 'stn',
  slug: 'stn',
  target: ''
};

const EXCO_AD: SponsorType = {
  name: 'exco',
  slug: 'exco',
  target: ''
};

const JERSEY_MIKES: SponsorType = {
  name: 'jerseymikes',
  slug: 'jersey_mike',
  target:
    'https://naismithtrophy.com/future-usc-trojans-judea-juju-watkins-and-isaiah-collier-win-2023-jersey-mikes-naismith-high-school-player-of-the-year-trophy/'
};

const FANAPP_AD: SponsorType = {
  name: 'fanapp',
  slug: 'fan-app',
  target: '/sblive-app'
};

export const TOP = 'top';
export const SCOREBOARD = 'scoreboard';
export const SCOREBOARD_WIDGET = 'scoreboard-widget';
export const HORIZONTAL_WIDGET = 'horizontal-widget';
export const RIGHT = 'right';
export const RIGHT_SECONDARY = 'right-secondary';
export const VIGNETTE = 'vignette';

export type PlacementType =
  | typeof TOP
  | typeof SCOREBOARD
  | typeof SCOREBOARD_WIDGET
  | typeof HORIZONTAL_WIDGET
  | typeof RIGHT
  | typeof RIGHT_SECONDARY
  | typeof VIGNETTE;
export type SizeType = 'banner' | 'square' | 'flag' | 'vignette';

export type VideoAdType = 'stn' | 'exco';

const SIZES = {
  [TOP]: 'banner',
  [SCOREBOARD]: 'banner',
  [SCOREBOARD_WIDGET]: 'banner',
  [HORIZONTAL_WIDGET]: 'flag',
  [RIGHT]: 'square',
  [RIGHT_SECONDARY]: 'square'
} as { [key in PlacementType]: SizeType };

function getSponsor({
  placement,
  state,
  fanApp,
  ios,
  android,
  city,
  isGoogleAd,
  isAdmin,
  videoAdType,
  isFanAppAd
}: {
  placement: PlacementType;
  state: string;
  fanApp: boolean;
  ios: boolean;
  android: boolean;
  city: string;
  isGoogleAd?: boolean;
  isAdmin?: boolean;
  videoAdType?: VideoAdType;
  isFanAppAd?: boolean;
}) {
  if (videoAdType === 'stn') return STN_AD;
  if (videoAdType === 'exco') return EXCO_AD;
  if (isFanAppAd) return FANAPP_AD;
  if (isAdmin) {
    if (placement != TOP) return null;
    if (state == 'TX' || state == 'OK' || state == 'NM') {
      return Math.round(Math.random() * 10) > 5 ? RANK_ONE : RANK_ONE_TWO;
    } else {
      return null;
    }
  }
  if (state == 'WIAA' && placement == HORIZONTAL_WIDGET) {
    return WIAA;
  } else if ('AZ' == state && (placement == SCOREBOARD || placement == RIGHT || placement == TOP)) {
    return ADOT;
  } else if (isGoogleAd && placement != RIGHT_SECONDARY) {
    // CHANGE FOR GOOGLE AD
    // return GOOGLE_AD;
  } else if (placement != RIGHT_SECONDARY && !fanApp) {
    return null;
    // if (android) {
    //   return FAN_APP_ANDROID;
    // }
    //
    // return FAN_APP_IOS;
  } else if (placement == RIGHT_SECONDARY && (state == 'WA' || state == 'OR' || state == 'ID' || state == 'AZ')) {
    return WA_FED;
  }
}

interface SponsorProps {
  placement: PlacementType;
  className?: string;
  isWIAA?: boolean;
  isGoogleAd?: boolean;
  isAdmin?: boolean;
  videoAdType?: VideoAdType;
  trackingTag?: string;
  isFanAppAd?: boolean;
}

export default function Sponsor({ placement, className, isWIAA, isGoogleAd, isAdmin, videoAdType, trackingTag, isFanAppAd }: SponsorProps) {
  const [{ isFanApp }, { handleOpenLink }] = useApplicationContainer();
  const [sponsor, setSponsor] = useState<SponsorType>();
  const { data: IPInfo } = useIpInfo();
  const size = SIZES[placement];
  const [inViewRef, inView] = useInView({ threshold: 1, triggerOnce: true });

  useEffect(() => {
    const sponsor = getSponsor({
      placement,
      state: isWIAA ? 'WIAA' : IPInfo?.region_code || 'CA',
      ios: /iPad|iPhone|iPod/.test(navigator.userAgent),
      android: /android/i.test(navigator.userAgent),
      fanApp: isFanApp,
      city: IPInfo?.city || 'San Diego',
      isGoogleAd,
      videoAdType,
      isAdmin,
      isFanAppAd
    });

    if (sponsor) setSponsor(sponsor);
  }, [IPInfo, isFanApp]);

  useEffect(() => {
    if (inView && sponsor) {
      if (sponsor == GOOGLE_AD) {
        triggerEvent(GOOGLE_AD_VIEW, { placement, size });
      } else {
        triggerEvent(SPONSOR_VIEW, { placement, size, sponsor });
        gaPageView({
          tracker: SPONSOR_TRACKER,
          path: `${window.location.pathname}/${sponsor.slug}/${size}`
        });
      }
    }
  }, [inView, sponsor]);

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    if (!sponsor) return;
    e.preventDefault();
    triggerEvent(SPONSOR_CLICK, { placement, size, sponsor });
    gaEvent({
      tracker: SPONSOR_TRACKER,
      event: {
        category: `Ad-${size}`,
        action: 'click',
        label: sponsor.name
      }
    });
    let targetUrl = sponsor.target;
    if (trackingTag && sponsor == WA_FED) {
      targetUrl += `?utm_source=athlete-of-the-week&utm_medium=Ad&utm_campaign=${trackingTag}`;
    }
    handleOpenLink(targetUrl, { newWindow: true });
  }

  if (!sponsor) return null;

  if (sponsor == GOOGLE_AD) {
    return <GoogleAd innerRef={inViewRef} className={cn('sponsor', `sponsor--${placement}`, `sponsor--${size}`, className)} />;
  } else if (sponsor == STN_AD) {
    return <StnAd />;
  } else if (sponsor == EXCO_AD) {
    return <ExcoAd />;
  } else {
    return (
      <a
        ref={inViewRef}
        href={sponsor.target}
        onClick={handleClick}
        className={cn('sponsor', `sponsor--${placement}`, `sponsor--${size}`, `sponsor--${sponsor.slug}`, className)}
      />
    );
  }
}
