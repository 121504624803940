import { useEffect, useRef } from 'react';

const ExcoAd = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//player.ex.co/player/9e52d4a4-a536-4f55-a74b-893d64a0273f';
    script.className = 'exco-player';
    const node = ref.current;

    if (node) {
      node.appendChild(script);
    }

    return () => {
      if (node) {
        node.removeChild(script);
      }
    };
  }, []);

  return <div id="9e52d4a4-a536-4f55-a74b-893d64a0273f" ref={ref} />;
};

export default ExcoAd;
