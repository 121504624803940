import { useEffect } from 'react';
import styles from './styles/VignetteSponsor.module.scss';

export default function StnAd() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://embed.sendtonews.com/player2/embedcode.php?fk=sdqCwiS9&cid=13553&floatwidth=400';
    script.async = true;
    script.type = 'text/javascript';
    script.setAttribute('data-type', 's2nScript');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    // <div>
    //   <div className='s2nPlayer-sdqCwiS9' datatype='barker'></div>
    // </div>
    null
  );
}
