import Image from './Image';
import Link from './Link';
import styles from './styles/ArticleBlock.module.scss';
import cn from 'classnames';
import Sponsor from './Sponsor';
import useScreenSize from 'hooks/useScreenSize';

export enum ArticleBlockLayout {
  OVERLAY = 'Overlay',
  HERO_LARGE = 'HeroLarge',
  HERO_SMALL = 'HeroSmall',
  FEED = 'Feed',
  TEXT_ONLY = 'TextOnly',
  CARD = 'Card'
}

interface ArticleBlockProps {
  article: {
    authorName: string;
    description?: string;
    imageAlt?: string;
    imageUrl?: string;
    path: string;
    title: string;
    genderSport?: { name: string };
  };
  layout: ArticleBlockLayout;
  first?: boolean;
  isAd?: boolean;
}

export default function ArticleBlock({ article, layout, first, isAd }: ArticleBlockProps) {
  const { isSmallScreen } = useScreenSize();

  if (isAd) {
    return (
      <div className={cn(styles.ArticleBlock, styles[layout], styles.ArticleAd)}>
        <Sponsor placement={isSmallScreen || layout === ArticleBlockLayout.FEED ? 'scoreboard' : 'right'} isGoogleAd />
      </div>
    );
  }
  return (
    <Link href={article.path} className={cn(styles.ArticleBlock, styles[layout], { [styles.First]: first })}>
      {layout !== ArticleBlockLayout.TEXT_ONLY && article.imageUrl && (
        <span className={styles.ImageContent}>
          <span className={styles.ImageContainer}>
            <Image src={article.imageUrl} alt={article.imageAlt || article.title} className={styles.Image} />
          </span>
        </span>
      )}
      <span className={styles.TextContent}>
        {article.genderSport && <span className={styles.GenderSport}>{article.genderSport.name}</span>}
        <span className={styles.Title}>{article.title}</span>
        {(layout === ArticleBlockLayout.HERO_LARGE || layout === ArticleBlockLayout.HERO_SMALL || layout === ArticleBlockLayout.CARD) &&
          article.description && <span className={styles.Description}>{article.description}</span>}
        <span className={styles.AuthorName}>{`By ${article.authorName}`}</span>
      </span>
    </Link>
  );
}
