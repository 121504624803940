import { selectTypename } from '@sblive-sports/api-client-js';

export default function filterArticles<
  T extends {
    __typename:
      | 'ContentItemPhotoGallery'
      | 'ContentItemPoll'
      | 'ContentItemTempestArticle'
      | 'ContentItemTweet'
      | 'ContentItemVoltaxArticle'
      | 'ContentItemYouTubeVideo';
  }
>(nodes: T[]) {
  return nodes.filter(selectTypename('ContentItemTempestArticle', 'ContentItemVoltaxArticle'));
}
