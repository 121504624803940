import { useContentContainer } from 'containers/ContentContainer';
import Fire from 'icons/Fire';
import ContentInfiniteFeed, { ContentFeedLayout } from 'containers/ContentContainer/components/ContentInfiniteFeed';
import useScreenSize from 'hooks/useScreenSize';
import ContentSmallHero from 'containers/ContentContainer/components/ContentSmallHero';
import ContentLargeHero from 'containers/ContentContainer/components/ContentLargeHero';
import ContentBlock from 'components/ContentBlock';
import styles from './styles/ContentIndexLayout.module.scss';
import cn from 'classnames';
import PageSection from '@/components/PageSection';
import { ScreenSize } from '@/components/ScreenSizes';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import MinuteMediaAd from '@/components/ads/MinuteMediaAd';
import StnVideoAd from '@/components/ads/StnVideoAd';

export interface ContentIndexLayoutProps {
  showHero?: boolean;
  showRight?: boolean;
}

export default function ContentIndexLayout({ showHero, showRight }: ContentIndexLayoutProps) {
  const [{ pathname }] = useApplicationContainer();
  const [{ articles }] = useContentContainer();
  const { isSmallScreen, isLargeScreen } = useScreenSize();

  return (
    <PageSection flush={ScreenSize.SMALL}>
      {showHero && articles.length > 0 && (isLargeScreen ? <ContentLargeHero /> : <ContentSmallHero />)}
      <div className={cn(styles.Bottom, { [styles.BottomColumns]: showRight })}>
        {showRight && (
          <div className={styles.RightColumn}>
            <MinuteMediaAd placement="SIDEBAR" />
          </div>
        )}

        {articles.length > 6 && (
          <ContentBlock title="The Latest" icon={<Fire />} className={styles.Feed}>
            {!isSmallScreen && <StnVideoAd placement="INLINE" className={styles.Video} />}
            <ContentInfiniteFeed startAt={showHero ? 7 : 1} layout={isSmallScreen ? ContentFeedLayout.LIST : ContentFeedLayout.CARDS} />
          </ContentBlock>
        )}
      </div>
    </PageSection>
  );
}
