import ArticleBlock, { ArticleBlockLayout } from 'components/ArticleBlock';
import { useContentContainer } from '..';
import ContentBlock from 'components/ContentBlock';

export default function ContentSmallHero() {
  const [{ articles }] = useContentContainer();

  return (
    <ContentBlock>
      <ArticleBlock article={articles[0]} layout={ArticleBlockLayout.OVERLAY} />
      {articles.slice(1, 6).map((article, i) => (
        <ArticleBlock key={article.id} article={article} layout={ArticleBlockLayout.FEED} first={i == 0} />
      ))}
    </ContentBlock>
  );
}
