import Store, { useStore } from 'stores';
import { localCache } from 'helpers/cache';
import { IP_STACK_API_KEY, ENVIRONMENT, DEVELOPMENT } from 'constants.js.erb';
import fetchJSON from 'helpers/fetchJSON';
import useBrowser from 'hooks/useBrowser';
import { useEffect } from 'react';

interface IPInfoResponse {
  ip: string;
  type: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip: string;
  latitude: number;
  longitude: number;
  location: {
    geoname_id: number;
    capital: string;
    languages: {
      code: string;
      name: string;
      native: string;
    }[];
    country_flag: string;
    country_flag_emoji: string;
    country_flag_emoji_unicode: string;
    calling_code: string;
    is_eu: boolean;
  };
  time_zone: {
    id: string;
    current_time: string;
    gmt_offset: number;
    code: string;
    is_daylight_saving: boolean;
  };
  currency: {
    code: string;
    name: string;
    plural: string;
    symbol: string;
    symbol_native: string;
  };
  connection: {
    asn: number;
    isp: string;
  };
}

export interface IpInfoStoreState {
  status: 'EMPTY' | 'FETCHING' | 'ERROR' | 'RESOLVED';
  data?: IPInfoResponse;
}

const ipInfoStore = new Store<IpInfoStoreState>({ status: 'EMPTY' }, 'ipInfoStore', localCache);

export function useIpInfo() {
  const ipInfo = useStore(ipInfoStore);
  //const isValidBrowser = useBrowser();

  //useEffect(() => {
  //  if (ipInfo.status !== 'EMPTY' || !isValidBrowser || ENVIRONMENT === DEVELOPMENT) return;
  //
  //  ipInfoStore.setState({ status: 'FETCHING' });
  //
  //  const IPInfo = localCache.get<IPInfoResponse>('IPInfo');
  //  if (IPInfo) {
  //    ipInfoStore.setState({ status: 'RESOLVED', data: IPInfo });
  //    return;
  //  }
  //
  //  fetchJSON<IPInfoResponse>({
  //    url: 'https://api.ipstack.com/check',
  //    data: {
  //      access_key: IP_STACK_API_KEY
  //    }
  //  })
  //    .then(ipInfo => {
  //      ipInfoStore.setState({ status: 'RESOLVED', data: ipInfo });
  //    })
  //    .catch(() => {
  //      ipInfoStore.setState({ status: 'ERROR' });
  //    });
  //}, []);

  return ipInfo;
}
