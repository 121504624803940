import { ReactNode } from 'react';
import styles from './styles/ContentBlock.module.scss';
import cn from 'classnames';

interface ContentBlockProps {
  title?: string;
  icon?: ReactNode;
  children: ReactNode;
  className?: string;
}

export default function ContentBlock({ title, icon, children, className }: ContentBlockProps) {
  return (
    <div className={cn(styles.Container, className)}>
      {(icon || title) && (
        <div className={styles.Header}>
          {icon}
          {title && <div className={styles.Title}>{title}</div>}
        </div>
      )}
      <div className={styles.Content}>{children}</div>
    </div>
  );
}
